import React, {Component} from 'react';
import DeloreanAnimation from "../Animations/DeloreanAnimation"
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  text-align: center;

  .title-wrapper {
    width: 90%;
    margin: 0px auto;
    max-width: 1350px;
  }
`
const Content = styled.div`

  margin: 0 auto;
  max-width: 900px;
  padding: 0 1rem;

`

class DeloreanBlock extends Component {
    render() {
        return (
            <Wrapper className="mt-3 mt-md-5">
                {this.props.newyork && (
                    <>
                       <div className="title-wrapper">
                       <h2 className="h1 mb-0">
                           TAKE AN ELECTRIFYING RIDE BACK IN TIME WITH THE OLIVIER AWARD WINNING BEST NEW MUSICAL
                       </h2>
                   </div>
                   <Content className="my-3 my-md-5">
                       <DeloreanAnimation/>
                       <p>Great Scott! The multi-award winning <strong>Back to the Future The Musical</strong> is changing Broadway musical theatre history.</p>
                       <p>When Marty McFly finds himself transported back to 1955 in a time machine built by the eccentric scientist Doc Brown, he accidentally changes the course of history. Now he&rsquo;s in a race against time to fix the present, escape the past and send himself... back to the future.</p>
                       <p>Experience this high-voltage comedy as the iconic story is adapted for the stage by the movie&rsquo;s creators Bob Gale (<em>Back to the Future</em>&nbsp;trilogy) and Robert Zemeckis (<em>Forrest Gump</em>) and is directed by the Tony Award-winning John Rando.</p>
                       <p>Back to the Future the Musical features&nbsp;original music by multi-Grammy winners&nbsp;Alan Silvestri (<em>Avengers: Endgame</em>) and Glen Ballard (Michael Jackson&rsquo;s&nbsp;<em>Man in the Mirror)</em>,&nbsp;alongside hit songs from the movie including&nbsp;<em>The Power of Love</em>,<em>&nbsp;Johnny B. Goode</em>,&nbsp;<em>Earth Angel</em>&nbsp;and&nbsp;<em>Back in Time</em>.</p>
                       <p>When&nbsp;<strong>BACK&nbsp;TO&nbsp;THE&nbsp;FUTURE&nbsp;THE&nbsp;MUSICAL</strong>&nbsp;hits 88mph, you&rsquo;re gonna see some serious&hellip; entertainment.</p>
                   </Content>
                   </>
                   
                )}
                {this.props.ustour && (
                    <>
                       <div className="title-wrapper">
                       <h2 className="h1 mb-0">
                           TAKE AN ELECTRIFYING RIDE BACK IN TIME WITH THE OLIVIER AWARD WINNING BEST NEW MUSICAL
                       </h2>
                   </div>
                   <Content className="my-3 my-md-5">
                       <DeloreanAnimation/>
                       <p>Great Scott! The multi-award winning <strong>Back to the Future The Musical</strong> is set to change musical theatre history.</p>
                       <p>When Marty McFly finds himself transported back to 1955 in a time machine built by the eccentric scientist Doc Brown, he accidentally changes the course of history. Now he&rsquo;s in a race against time to fix the present, escape the past and send himself... back to the future.</p>
                       <p>Experience this high-voltage comedy as the iconic story is adapted for the stage by the movie&rsquo;s creators Bob Gale (<em>Back to the Future</em>&nbsp;trilogy) and Robert Zemeckis (<em>Forrest Gump</em>) and is directed by the Tony Award-winning John Rando.</p>
                       <p>Back to the Future the Musical features&nbsp;original music by multi-Grammy winners&nbsp;Alan Silvestri (<em>Avengers: Endgame</em>) and Glen Ballard (Michael Jackson&rsquo;s&nbsp;<em>Man in the Mirror)</em>,&nbsp;alongside hit songs from the movie including&nbsp;<em>The Power of Love</em>,<em>&nbsp;Johnny B. Goode</em>,&nbsp;<em>Earth Angel</em>&nbsp;and&nbsp;<em>Back in Time</em>.</p>
                       <p>When <strong className='break-word'>BACK TO THE FUTURE THE MUSICAL</strong> hits 88mph, you&rsquo;re gonna see some serious&hellip; entertainment.</p>
                   </Content>
                   </>
                   
                )}
                {this.props.london && (
                    <>
                         <div className="title-wrapper">
                        <h2 className="h1 mb-0">WINNER OF BEST NEW MUSICAL <br />TIME AND TIME AGAIN…</h2>
                    </div>
                    <p>WhatsOnStage Awards 2022 | Olivier Awards 2022 | Broadway World Awards 2022</p>
                    <Content className="my-3 my-md-5">

                    <DeloreanAnimation/>
                    <p>Great Scott! It&rsquo;s time for &ldquo;perfect musical escapism&rdquo; (Metro) with the multi-award winning&nbsp;<strong>Back to the Future The Musical.</strong> The 1985 blockbuster film and pop culture phenomenon is electrifying audiences and critics alike.</p>
                    <p>When Marty McFly finds himself transported back to 1955 in a time machine built by the eccentric scientist Doc Brown, he accidentally changes the course of history. Now he&rsquo;s in a race against time to fix the present, escape the past and send himself... back to the future.</p>
                    <p>Experience this &ldquo;high-voltage comedy&rdquo; (Metro) and &ldquo;feelgood triumph&rdquo; (Telegraph) as the iconic story is adapted for the stage by the movie&rsquo;s creators Bob Gale (<strong><em>Back to the Future</em></strong>&nbsp;trilogy) and Robert Zemeckis (<strong><em>Forrest Gump</em></strong>). Directed by Tony Award-winning John Rando, this &ldquo;all-out spectacle&rdquo; (The Stage) will make you think the DeLorean car is actually &ldquo;defying the laws of theatre&rdquo; (Guardian).</p>
                    <p>Back to the Future the Musical features original music by multi-Grammy winners Alan Silvestri (<strong><em>Avengers: Endgame</em></strong>) and Glen Ballard (Michael Jackson&rsquo;s&nbsp;<strong><em>Man in the Mirror</em></strong>), alongside hit songs from the movie including&nbsp;<strong><em>The Power of Love</em></strong><strong>,</strong><strong><em>&nbsp;Johnny B. Goode</em></strong><strong>,&nbsp;</strong><strong><em>Earth Angel</em></strong>&nbsp;and&nbsp;<strong><em>Back in Time</em></strong><strong>.</strong></p>
                    <p>&ldquo;It&rsquo;s a total blast&rdquo; says the Metro, because when this baby hits 88mph, it &ldquo;zaps the audience between the eyes and ears to a roar of delight&rdquo; (Variety).</p>
                    <p>Book your tickets yesterday!</p>
                    </Content>
                    </>
                )}
               
            </Wrapper>
        );
    }
}

export default DeloreanBlock
